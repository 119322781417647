<template>
  <div class="orm-customer-widget" v-if="hasLogin == 'J'">
    <div>
      <button
        v-if="!islogin"
        class="orm-btn--icon orm-login-widget-btnlistener"
        :class="showCustomerOverlay ? 'orm-btn--icon-active' : ''"
        @click="
          showCustomerOverlay = !showCustomerOverlay;
          openModal();
        "
        aria-label="Login Menu"
      >
        <i class="fal fa-user-circle"></i>
        <span v-if="loginicontext" class="orm-login-widget-btnlistener">{{
          i18n("general/login") || "Anmelden"
        }}</span>
      </button>
      <button
        v-if="islogin"
        class="orm-btn--icon orm-login-widget-btnlistener"
        :class="showCustomerOverlay ? 'orm-btn--icon-active' : ''"
        @click="
          showCustomerOverlay = !showCustomerOverlay;
          openModal();
        "
        aria-label="Login Menu"
      >
        <i class="fal fa-user-circle"></i>
        <span v-if="loginicontext" class="orm-login-widget-btnlistener">{{
          i18n("general/myLogin") || "Mein Account"
        }}</span>
        <!-- <div
          class="orm-login-widget-btnlistener"
          :class="
            loginicontext
              ? 'orm-login__icon--loggedin orm-login__icon--loggedin--text'
              : 'orm-login__icon--loggedin'
          "
        >
          
        </div> -->
      </button>
    </div>
    <div
      v-if="showCustomerOverlay"
      class="orm-customer-widget-overlay orm-dropdown-menu"
    >
      <Login
        v-if="islogin == null"
        @get-login="getLogin"
        @show-customer-overlay="
          showCustomerOverlay = !showCustomerOverlay;
          openModal();
        "
      />
      <Loggedin
        v-if="islogin"
        @get-logout="getLogin"
        @show-customer-overlay="
          showCustomerOverlay = !showCustomerOverlay;
          openModal();
        "
        @showContact="
          showContact = !showContact;
          showCustomerOverlay = !showCustomerOverlay;
        "
      />
    </div>
  </div>
  <Contact
    v-if="showContact"
    type="general"
    @dismiss="showContact = !showContact"
  />
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
// import { slugMe } from "@/constants/formater";
import i18n from "@/mixins/mixins";

import Login from "@/components/customer/login.vue"; // @ is an alias to /src
import Loggedin from "@/components/customer/loggedin.vue"; // @ is an alias to /src
import Contact from "@/components/modals/modal-contact.vue"; /* webpackChunkName: 'Contact.chunk' */

export default defineComponent({
  name: "Footer", // lustiger name ;P
  components: { Login, Loggedin, Contact },
  mixins: [i18n],
  emits: ["loginState"],
  props: {
    teleport: {
      default: true,
    },
  },
  data() {
    return {
      islogin: null,
      showCustomerOverlay: false,
      showContact: false,
      hasLogin: "N",
    };
  },
  setup() {
    // console.log(props);
    let widget = false;
    const myEle = document.getElementById("crentCustomerWidget");
    let loginicontext = true;
    const crentWrapper = document.getElementById("crentReservation");
    if (crentWrapper !== null) {
      loginicontext =
        crentWrapper.dataset.loginicontext === "true" ? true : false;
    }
    if (myEle !== null) {
      widget = true;
      loginicontext = myEle.dataset.loginicontext === "true" ? true : false;
    }
    return {
      widget,
      loginicontext,
      store,
    };
  },
  async mounted() {
    this.islogin = await store.getters["userSession/IS_LOGGEDIN"];
    this.hasLogin = store.getters["ORMgeneral/GET_MODULE"]("ORM_LOGIN");
  },
  methods: {
    async getLogin() {
      this.islogin = await store.getters["userSession/IS_LOGGEDIN"];
      this.$emit("loginState", true);
    },
    openModal() {
      const isTypo3 = document.getElementsByClassName(
        "orm-login-widget--typo3"
      );
      const ormBurger = document.getElementsByClassName("orm-burger");
      if (
        isTypo3.length > 0 &&
        window.getComputedStyle(ormBurger[0]).display !== "none"
      ) {
        if (this.showCustomerOverlay) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      } else {
        document.body.style.overflow = "";
      }
    },
  },
});
</script>
