import apiCall from "../../api/crentAPI";
import store from "@/store";
import {
  getDistanceFromLatLonInKm,
  formateDatetime,
  formateDate,
  formatHour,
  formateTimeArray,
  slugMe,
} from "@/constants/formater";
const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";

// initial state
const state = () => ({
  stationlist: [],
  stationlistfiltered: {},
  stationlistsorted: [],
  stationlistsortedfiltered: [],
  opentimes: false,
  opentimesStore: [],
  closedDays: [],
  closedStartDays: [],
  closedEndDays: [],
});

// getters
const getters = {
  GET_STATIONS: (state) => state.stationlist || [],
  GET_STATIONS_SORTED: (state) => state.stationlistsorted || [],
  GET_STATIONS_FILTERED: (state) => state.stationlistfiltered || [],
  GET_STATION_BY_ID: (state) => (stationID) => {
    return state.stationlist.find((station) => station.id === stationID);
  },
  GET_STATION_BY_NAME: (state) => (name) => {
    return state.stationlist.find((station) => station.name === name);
  },
  GET_STATION_BY_SLUG: (state) => (name) => {
    return state.stationlist.find((station) => slugMe(station.name) === name);
  },
  GET_OPENINGTIMES: (state) => state.opentimes || null,
  GET_CLOSEDDAYS: (state) => state.closedDays,
  GET_CLOSEDSTARTDAYS: (state) => state.closedStartDays,
  GET_CLOSEDENDDAYS: (state) => state.closedEndDays,
};

// actions
const actions = {
  async SET_ALLSTATIONS({ commit }) {
    const types = ["ALL", "RENT", "SHARE", "ABO", "SPECIAL"];
    const payload = "";
    // eslint-disable-next-line
    let status = false;
    const crentReservation = document.getElementById("crentReservation");
    let stationtype = ["RENT"];
    if (crentReservation) {
      stationtype = crentReservation.dataset.stationtype.split(",");
    }
    if (stationtype[0] === "ALL") {
      for (const type of types) {
        await apiCall.get("/api/rent/stations", { type: type }, (stations) => {
          if (stations.length > 0) {
            commit("MUTATE_STATIONS_FILTERED", { type, stations });
          }
          if (stations.error || stations.errorCode) {
            status = false;
            return ["stations/SET_ALLSTATIONS", false];
          } else {
            status = true;
          }
          // if (store.getters["userSession/GET_STATION"] == null) {
          //   store.dispatch("userSession/SET_STATION_INIT", stations[0].id);
          // }
        });
      }
    } else {
      for (const type of stationtype) {
        await apiCall.get("/api/rent/stations", { type: type }, (stations) => {
          if (stations.length > 0) {
            commit("MUTATE_STATIONS_FILTERED", { type, stations });
          }
          if (stations.error || stations.errorCode) {
            status = false;
            return ["stations/SET_ALLSTATIONS", false];
          } else {
            status = true;
          }
          // if (store.getters["userSession/GET_STATION"] == null) {
          //   store.dispatch("userSession/SET_STATION_INIT", stations[0].id);
          // }
        });
      }
    }
    await apiCall.get("/api/rent/stations", payload, (stations) => {
      commit("MUTATE_STATIONS", stations);
      if (stations.error || stations.errorCode) {
        status = false;
        return ["stations/SET_ALLSTATIONS", false];
      } else {
        status = true;
      }
    });
    return ["stations/SET_ALLSTATIONS", status];
  },

  async SET_OPENINGTIMES({ commit, state }, station) {
    const date = JSON.parse(
      JSON.stringify(await store.getters["userSession/GET_RESERVATIONDATE"])
    );

    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];
    const endstation = await store.getters["userSession/GET_STATION_END"];
    const language = await store.getters["general/GET_LANGUAGE"];
    const timeNow = formatHour(new Date());
    const offers = await store.getters["offers/GET_OFFERSELECTED"];

    const abolength = await store.getters["reservation/GET_ABO_LENGTH"];
    const branch = await store.getters["userSession/GET_BRANCH"];
    let selectedCargroupInfo;
    if (branch === "Auto-Abo" && abolength) {
      selectedCargroupInfo = await store.getters["userSession/GET_VEHICLE"];
      if (selectedCargroupInfo) {
        if (selectedCargroupInfo.available_from) {
          const strtDt = new Date(selectedCargroupInfo.available_from);
          const endDt = new Date(date.start);

          if (endDt < strtDt) {
            const dateOnly = formateDate(selectedCargroupInfo.available_from);
            date.start = dateOnly;
          }
        }
      }
      // console.log("selectedCargroupInfo", selectedCargroupInfo);
      // console.log("date.start", date.start);
      const newDate = new Date(date.start);
      const selectedAbo = await store.getters["reservation/GET_SELECTEDABO"];
      // console.log("date.start", date.start);
      // console.log("selectedAbo", selectedAbo);

      date.end = formateDate(
        newDate.setMonth(newDate.getMonth() + parseInt(selectedAbo[0]))
      );
    }

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";

    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st + "Z";
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st + "Z";
    } else {
      endTimeDate = formateDatetime(Date.now());
    }

    let payload = {};
    if (station == "preload" || startstation == null) {
      payload = {
        clientDateStartingPoint: formateDatetime(Date.now()),
        selectedStartDateTime: startTimeDate,
        selectedEndDateTime: endTimeDate,
        language: language,
      };
    } else {
      payload = {
        // action: "getStationData",
        // eslint-disable-next-line
        startstation: startstation,
        // eslint-disable-next-line
        endstation: endstation,
        // eslint-disable-next-line
        clientDateStartingPoint: formateDatetime(Date.now()),
        selectedStartDateTime: startTimeDate,
        selectedEndDateTime: endTimeDate,
        language: language,
        rate_code: offers.rate_code || "",
      };
    }
    let status = false;
    function formattime(date) {
      date = new Date(date);
      const hours = date.getHours();
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes;
      return strTime;
    }
    if (state.opentimesStore.length > 0 && branch !== "Auto-Abo") {
      const timesstore = JSON.parse(JSON.stringify(state.opentimesStore));
      const paystore = JSON.parse(JSON.stringify(payload));
      paystore["clientDateStartingPoint"] = formattime(
        payload.clientDateStartingPoint
      );
      delete paystore["apiurl"];
      delete paystore["sid"];
      timesstore[0].clientDateStartingPoint = formattime(
        state.opentimesStore[0].clientDateStartingPoint
      );
      timesstore[0]["apiurl"] = encodeURI(state.opentimesStore[0]["apiurl"]);
      delete timesstore[0]["apiurl"];
      delete timesstore[0]["sid"];

      // clientDateStartingPoint
      if (JSON.stringify(timesstore[0]) == JSON.stringify(paystore)) {
        return state.opentimesStore[1];
      }
    }
    // console.log(payload);
    // eslint-disable-next-line
    await apiCall.get("/api/rent/stations/opentimes", payload, (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["stations/SET_OPENINGTIMES", status];
      } else {
        status = true;
      }
      // if (offers) {
      //   // result.selected_start_date = "2023-04-04T16:00:00";
      //   result.max_end_date = "2023-04-19T16:00:00";
      //   result.min_start_date = "2023-04-04T16:00:00";
      //   console.log(result);
      // }
      // console.log("result-before", result);
      if (branch === "Auto-Abo" && abolength) {
        selectedCargroupInfo = store.getters["userSession/GET_VEHICLE"];
        if (selectedCargroupInfo) {
          console.log("SET MIN_DATE", selectedCargroupInfo.available_from);
          console.log(result.min_start_date);

          if (selectedCargroupInfo.available_from) {
            result.min_start_date = selectedCargroupInfo.available_from;
            const strtDt = new Date(selectedCargroupInfo.available_from);
            const endDt = new Date(result.min_start_date);
            if (endDt > strtDt) {
              result.selected_start_date = selectedCargroupInfo.available_from;
            }
          }
        }
        // const newDate = new Date(result.selected_start_date);
        // const selectedAbo = store.getters["reservation/GET_SELECTEDABO"];
        // console.log(selectedAbo);
        // result.min_end_date = formateDatetime(
        //   newDate.setDate(newDate.getDate() + parseInt(selectedAbo[1]) - 1)
        // );
        // result.selected_end_date = formateDatetime(
        //   newDate.setDate(newDate.getDate() + parseInt(selectedAbo[1]) - 1)
        // );
      }
      // console.log("result", result);
      commit("MUTATE_OPENINGTIMES", [result, payload]);

      const daterange = {
        start: formateDate(result.selected_start_date),
        end: formateDate(result.selected_end_date),
      };
      const timerange = {
        start: formateTimeArray(result.selected_start_date),
        end: formateTimeArray(result.selected_end_date),
      };

      store.dispatch("userSession/SET_RESERVATIONDATE", daterange);
      store.dispatch("userSession/SET_RESERVATIONTIME", timerange);
    });
    return ["stations/SET_OPENINGTIMES", status];
  },
  SORT_STATIONS({ commit }, payload) {
    commit("MUTATE_DISTANCE", payload);
  },
};

// mutations
const mutations = {
  MUTATE_STATIONS(state, stations) {
    state.stationlist = stations;
    // state.stationlistsorted = stations;
    localStorage.setItem(ENV + "stations", JSON.stringify(stations));
  },
  MUTATE_STATIONS_FILTERED(state, item) {
    state.stationlistfiltered[item.type] = item.stations;
    state.stationlistsorted[item.type] = item.stations;
    localStorage.setItem(
      ENV + "stationsFiltered",
      JSON.stringify(state.stationlistfiltered)
    );
  },
  // SETS CLOSED DAYS FOR DATEPICKER
  MUTATE_OPENINGTIMES(state, result) {
    state.opentimesStore = [result[1], result[0]];
    state.opentimes = result[0];
    const closedDay = result[0].closed_days_config;
    const closedDayArray = [];
    closedDay.forEach((element) => {
      const date = element.date.split("T");
      const found = closedDayArray.find((element) => element === date[0]);
      if (!found) closedDayArray.push(date[0]);
    });
    state.closedDays = closedDayArray;

    const closedStartDay = result[0].start_closed_days_config;
    const closedStartDayArray = [];
    closedStartDay.forEach((element) => {
      const date = element.date.split("T");
      const found = closedStartDayArray.find((element) => element === date[0]);
      if (!found) closedStartDayArray.push(date[0]);
    });

    state.closedStartDays = closedStartDayArray;

    const closedEndDay = result[0].end_closed_days_config;
    const closedEndDayArray = [];
    closedEndDay.forEach((element) => {
      const date = element.date.split("T");
      const found = closedEndDayArray.find((element) => element === date[0]);
      if (!found) closedEndDayArray.push(date[0]);
    });
    state.closedEndDays = closedEndDayArray;
  },
  MUTATE_DISTANCE(state, payload) {
    const stationtype = payload.stationtype;

    const stations = JSON.parse(
      JSON.stringify(state.stationlistfiltered[stationtype])
    );

    for (const element in stations) {
      const distance = getDistanceFromLatLonInKm(
        stations[element].latitude,
        stations[element].longitude,
        payload.pos
      );
      stations[element].distance = distance;
    }

    function compare(a, b) {
      if (a.distance < b.distance) return -1;
      if (a.distance > b.distance) return 1;
      return 0;
    }
    const newsort = JSON.parse(JSON.stringify(stations));
    newsort.sort(compare);
    state.stationlistsorted[stationtype] = newsort;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
