const apiURL = process.env.VUE_APP_API_ENDPOINT; //"/api";
import store from "@/store";
import withQuery from "with-query";

const checktoken = async () => {
  const isLoggin = await store.getters["userSession/GET_TOKEN"];
  const expiresAt = JSON.parse(isLoggin.expire);
  const routerAuthCheck = new Date().getTime() < expiresAt;
  let token = isLoggin.token;
  if (!routerAuthCheck && isLoggin.expire) {
    store.dispatch("userSession/LOGOUT");
    token = null;
  }
  return token;
};

export default {
  async get(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await checktoken();
    const lang = await store.getters["general/GET_LANGUAGE"];

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
        payload["sid"] = token;
      }
      if (payload == "") {
        payload = { apiurl: apiurl, sid: token };
      }
    }
    /* eslint-disable */
    let requestOptions;

    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "GET",
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          language: lang,
        },
      };
    }

    let status;

    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      // .then((response) => response.json())
      .then(
        async (json) => {
          const data = json;
          if (data.code == "401" && data.message === "Invalid JWT Token") {
            await store.dispatch("userSession/LOGOUT");
            location.reload();
          }
          cb(data);
          //   return levels;
        },
        () => {
          // #  console.log(error.status);
          // throw status;
          // console.log("Error loading json:", JSON.parse(JSON.stringify(error)));
          cb({ error: status });
        }
      )
      .catch((error) => cb("error", error));
    /* eslint-enable */
  },

  async getHeaders(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const lang = await store.getters["general/GET_LANGUAGE"];

    const token = await checktoken();
    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
        payload["sid"] = token;
      }
      if (payload == "") {
        payload = { apiurl: apiurl, sid: token };
      }
    }
    /* eslint-disable */
    let requestOptions;
    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "GET",
        headers: {
          language: lang,
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          language: lang,
        },
      };
    }
    let status;
    let data;
    let concatroute = apiURL + route;
    if (route.search("http") != "-1") {
      // eslint-disable-next-line
      concatroute = route;
    }
    await fetch(withQuery(concatroute, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then(
        (json) => {
          data = json;
          cb({ status: status, data: data });
        },
        () => {
          cb({ error: status, data: data });
        }
      )
      .catch((error) => cb("error", { error: status, data: error }));

    return { status: status, data: data };
  },

  async post(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await checktoken();

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
        payload["sid"] = token;
      }
      if (payload == "") {
        payload = { apiurl: apiurl, sid: token };
      }
    }

    // POST request using fetch with error handling

    let requestOptions;

    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
      };
    }
    let data = Object;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        data = json;
        cb(data);
      })
      .catch((error) => {
        //this.errorMessage = error;
        console.error("There was an error!", error);
        cb(error);
        return error;
      });

    return data;
  },

  async postHeaders(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await checktoken();

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
        payload["sid"] = token;
      }
      if (payload == "") {
        payload = { apiurl: apiurl, sid: token };
      }
    }
    // POST request using fetch with error handling
    let requestOptions;

    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
      };
    }
    let data;
    let status;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((json) => {
        cb({ status: status, data: json });
      })
      .catch((error) => {
        //this.errorMessage = error;
        if (status == "204") {
          // console.error("There was an error!", error);
          cb({ status: status, data: "No Content" });
        } else {
          console.error("There was an error!", error);
          cb(error);
        }

        return error;
      });

    return { status: status, data: data };
  },

  async putHeaders(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await store.getters["userSession/GET_TOKEN"].token;

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
        payload["sid"] = token;
      }
      if (payload == "") {
        payload = { apiurl: apiurl, sid: token };
      }
    }
    // POST request using fetch with error handling
    let requestOptions;

    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "PUT",
        body: JSON.stringify(payload),
      };
    }
    let data;
    let status;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json().catch((err) => {
          console.error(`'${err}' happened, but no big deal!`);
          return {};
        });
        // return response.json();
      })
      .then((json) => {
        console.log(json);

        cb({ status: status, data: json });
      })
      .catch((error) => {
        //this.errorMessage = error;
        console.error("There was an error!", error);
        cb(error);
        return error;
      });

    return { status: status, data: data };
  },

  async getConcardis(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await store.getters["userSession/GET_TOKEN"].token;

    let requestOptions;
    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "GET",
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "GET",
      };
    }

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
      }
      if (payload == "") {
        payload = { apiurl: apiurl };
      }
    }
    // const requestOptions = {
    //   method: "GET",
    // };
    let status;
    let data;

    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then(
        (json) => {
          data = json;
          cb({ status: status, data: data });
        },
        () => {
          cb({ error: status, data: data });
        }
      )
      .catch((error) => cb("error", error));

    return { status: status, data: data };
  },

  async postConcardis(route, payload, cb) {
    const apiurl = await store.getters["ORMgeneral/GET_API_URL"];
    const token = await store.getters["userSession/GET_TOKEN"].token;

    if (apiurl) {
      if (typeof payload === "object") {
        payload["apiurl"] = apiurl;
      }
      if (payload == "") {
        payload = { apiurl: apiurl };
      }
    }
    // POST request using fetch with error handling
    let requestOptions;

    if (process.env.NODE_ENV == "development") {
      const code = process.env.VUE_APP_AUTHCODE;
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          authcode: code,
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token ? "Bearer " + token : "",
        },
      };
    } else {
      requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
      };
    }
    let data;
    let status;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((json) => {
        cb({ status: status, data: json });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        cb(error);
        return error;
      });

    return data;
  },
};
