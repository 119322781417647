import store from "@/store";
// import { convertCurrency } from "@/constants/formater";

const generateDataLayerValue = async (type, options) => {
  const getItemVariation = async () => {
    const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];

    const time =
      "Zeitraum " +
      (await generateDataLayerValue("getReservationDuration", options)) +
      " Tag(e)";

    let extras = "";
    let tarif = "";
    let insurance_rates = "";
    let delivery = "";
    if (succ.invoice_positions) {
      tarif = succ.invoice_positions.mainRates[0]
        ? succ.invoice_positions.mainRates[0].description
        : "";

      insurance_rates = succ.invoice_positions.insuranceRates[0]
        ? succ.invoice_positions.insuranceRates[0].description
        : "";
      delivery =
        succ.invoice_positions.deliveryPickupRates.length > 0
          ? "Zustellung/Abholung, "
          : "";

      succ.invoice_positions.subRates.forEach((element) => {
        extras += element.description + ", ";
      });
    } else {
      tarif = succ.main_rates[0] ? succ.main_rates[0].description : "";

      insurance_rates = succ.insurance_rates[0]
        ? succ.insurance_rates[0].description
        : "";
      delivery =
        succ.delivery_pickup_rates.length > 0 ? "Zustellung/Abholung, " : "";

      succ.sub_rates.forEach((element) => {
        extras += element.description + ", ";
      });
    }

    const result =
      time + ", " + tarif + ", " + insurance_rates + ", " + delivery + extras;
    // console.log(result);
    return result;
  };
  // console.log("generateDataLayerValue_type", type);
  const sess = await store.getters["userSession/GET_SESSION"];
  switch (type) {
    case "getStationCarListId": {
      return sess.selectedStation + "_carlist";
    }
    case "getCarList": {
      const GET_CARGROUPS = await store.getters[
        "cargroup/GET_GROUPS_FROM_SELECTEDSTATION"
      ];
      const filterdCar = [];
      GET_CARGROUPS.forEach((element) => {
        filterdCar.push({
          item_id: element.id,
          item_name: element.text_web,
          affiliation: sess.selectedStation,
          quantity: 1,
        });
      });
      return filterdCar;
    }
    case "getCarItem": {
      const GET_CARGROUP = await store.getters["userSession/GET_CARGROUP"];
      const GET_CARGROUPS = await store.getters[
        "cargroup/GET_GROUPS_FROM_SELECTEDSTATION"
      ];
      const index =
        GET_CARGROUPS.findIndex((element) => element.id === GET_CARGROUP) + 1;

      const GET_CARGROUP_BY_ID = await store.getters[
        "cargroup/GET_CARGROUP_BY_ID"
      ](GET_CARGROUP);
      const item_category = GET_CARGROUP_BY_ID.vehicle_category[0].id;

      // GET_CARGROUP_BY_ID.vehicle_category.forEach((element) => {
      //   item_category += element.id + ",";
      // });

      const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];

      const coupon = await generateDataLayerValue("getCoupon", options);
      let discount = 0;

      let total_uncouponedprice =
        parseFloat(succ.total_price_delivery_pickup) +
        parseFloat(succ.total_price_insurance) +
        parseFloat(succ.total_price_main_rates) +
        parseFloat(succ.total_price_payment) +
        parseFloat(succ.total_price_subrates);

      if (succ.voucher_rates) {
        discount =
          succ.voucher_rates.length > 0
            ? Math.abs(succ.voucher_rates[0].long_price)
            : 0;
      }
      if (succ.invoice_positions) {
        discount =
          succ.invoice_positions.voucherRates.length > 0
            ? Math.abs(succ.invoice_positions.voucherRates[0].long_price)
            : 0;

        total_uncouponedprice =
          parseFloat(succ.invoice_positions.endPositions[2].total_price) +
          parseFloat(discount);
      }
      const selectedOffer = await store.getters["offers/GET_OFFERSELECTED"];
      let promotion_id_to_string = "";
      let promotion_name;
      if (Object.keys(selectedOffer).length > 0) {
        promotion_id_to_string = selectedOffer.rate_code;
        promotion_name = selectedOffer.headline;
      }
      let promotion_id;
      if (promotion_id_to_string !== "") {
        promotion_id = promotion_id_to_string;
      }
      const returnObject = {
        item_id: GET_CARGROUP_BY_ID.id,
        item_name: GET_CARGROUP_BY_ID.text_web,
        affiliation: sess.selectedStation,
        coupon,
        discount,
        index,
        item_brand: store.getters["userSession/GET_STATIONTYPE"],
        item_category,
        quantity: 1,
        price: total_uncouponedprice,
        promotion_id,
        promotion_name,
        item_variant: await getItemVariation(),
      };
      if (coupon == "") {
        delete returnObject.coupon;
        delete returnObject.discount;
      }
      return [returnObject];
    }
    case "getCoupon": {
      const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
      // console.log(succ);
      let coupon = "";
      if (succ.voucher_rates) {
        coupon =
          succ.voucher_rates.length > 0 ? succ.voucher_rates[0].rate : "";
      }
      if (succ.invoice_positions) {
        coupon =
          succ.invoice_positions.voucherRates.length > 0
            ? succ.invoice_positions.voucherRates[0].rate
            : "";
      }

      return coupon;
    }
    case "getCarCategory": {
      const GET_CARGROUP = await store.getters["userSession/GET_CARGROUP"];
      const GET_CARGROUP_BY_ID = await store.getters[
        "cargroup/GET_CARGROUP_BY_ID"
      ](GET_CARGROUP);
      if (options.car) {
        return options.car.category || "";
      }
      if (GET_CARGROUP_BY_ID) {
        return GET_CARGROUP_BY_ID.vehicle_category[0].id;
      } else {
        return;
      }
    }
    case "getCarGroup": {
      const GET_CARGROUP = await store.getters["userSession/GET_CARGROUP"];
      const GET_CARGROUP_BY_ID = await store.getters[
        "cargroup/GET_CARGROUP_BY_ID"
      ](GET_CARGROUP);
      if (options.car) {
        return options.car.id || "";
      }
      if (GET_CARGROUP_BY_ID) {
        return GET_CARGROUP_BY_ID.text_web;
      } else {
        return;
      }
    }

    case "getVehicleId": {
      return "getVehicleId";
    }
    case "getStationStart": {
      return sess.selectedStation || "";
    }
    case "getStationEnd": {
      return sess.selectedStationEnd || sess.selectedStation;
    }
    case "getReservationDuration": {
      const date_1 = new Date(sess.reservationDate.start);
      const date_2 = new Date(sess.reservationDate.end);

      const days = (date_1, date_2) => {
        const difference = date_1.getTime() - date_2.getTime();
        const TotalDays = -Math.ceil(difference / (1000 * 3600 * 24));
        if (TotalDays == 0) return 1;
        return TotalDays;
      };
      return days(date_1, date_2);
    }
    case "getTimeStamp": {
      return new Date().getTime();
    }
    case "getScreenSize": {
      return window.screen.width + "x" + window.screen.height;
    }
    case "getTotalPrice": {
      // const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
      // console.log(succ);
      if (await store.getters["reservation/GET_RESERVATION"]) {
        const GET_RESERVATION = await store.getters[
          "reservation/GET_RESERVATION"
        ];
        return Math.abs(GET_RESERVATION.end_positions[2].total_price);
      } else if (await store.getters["reservation/GET_RESERVATION_SUCCESS"]) {
        const GET_RESERVATION = await store.getters[
          "reservation/GET_RESERVATION_SUCCESS"
        ];
        return Math.abs(
          GET_RESERVATION.invoice_positions.endPositions[2].total_price
        );
      } else {
        return;
      }
    }
    case "getCurrency": {
      return "EUR";
    }
    case "getSelectValue": {
      return options;
    }
    case "getDealerName": {
      const station = await store.getters["stations/GET_STATIONS_FILTERED"];

      const selected = station["ALL"].find(
        (station) => station.id === sess.selectedStation
      );

      return selected.name;
    }
    case "getDealerAddress": {
      const station = await store.getters["stations/GET_STATIONS_FILTERED"];

      const selected = station["ALL"].find(
        (station) => station.id === sess.selectedStation
      );

      return selected.street + ", " + selected.zipcode + " " + selected.city;
    }
    case "getDealerId": {
      return "getDealerId";
    }
    case "getLeadId": {
      return "getLeadId";
    }
    case "getTicked": {
      if (options) {
        return "ticked";
      } else {
        return "unticked";
      }
    }
    case "getDestination": {
      return options.destination;
    }
    case "getExtraPrice": {
      return options.extra.total_long_price;
    }
    case "getExtraLabel": {
      return options.extra.description;
    }
    case "getAddRemove": {
      return options.amount == 0 ? "remove" : "add";
    }
    case "getPaymentMethod": {
      const form = await store.getters["reservation/GET_CUSTOMER"];
      const cargroup = await store.getters["userSession/GET_CARGROUP"];
      const paymentMethods = await store
        .dispatch("reservation/GET_PAYMENT_METHODS", cargroup)
        .then((result) => {
          return result;
        });
      if (paymentMethods.find((x) => x.id == form.payment_method_id)) {
        return paymentMethods.find((x) => x.id == form.payment_method_id)
          .designation_web;
      } else {
        return "OnlinePayment";
      }
    }
    case "getReservationNumber": {
      const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
      return succ.id;
    }
    case "getTax": {
      const succ = await store.getters["reservation/GET_RESERVATION_SUCCESS"];

      let tax = "";
      if (succ.end_positions) {
        tax =
          succ.end_positions.length > 0
            ? succ.end_positions[1].total_price
            : "";
      }
      if (succ.invoice_positions.endPositions) {
        tax =
          succ.invoice_positions.endPositions.length > 0
            ? succ.invoice_positions.endPositions[1].total_price
            : "";
      }
      return tax;
    }
    case "getPromotionId": {
      const selectedOffer = await store.getters["offers/GET_OFFERSELECTED"];
      let promotion_id_to_string = "";

      if (Object.keys(selectedOffer).length > 0) {
        promotion_id_to_string = selectedOffer.rate_code;
      }
      let promotion_id;
      if (promotion_id_to_string !== "") {
        promotion_id = promotion_id_to_string;
      }
      return promotion_id;
    }
    case "getPromotionName": {
      const selectedOffer = await store.getters["offers/GET_OFFERSELECTED"];

      let promotion_name;
      if (Object.keys(selectedOffer).length > 0) {
        promotion_name = selectedOffer.headline;
      }

      return promotion_name;
    }
    default:
      return type;
  }
};

const generateDataLayer = async (eventtype, options) => {
  const config = JSON.parse(process.env.VUE_APP_ANALYTICS_CONFIG);
  // console.log("config", config);
  const event = config.orm[eventtype] || {};
  const attributes = JSON.parse(JSON.stringify(event));

  for (const type in event) {
    // console.log("event", typeof event[type]);
    // console.log("event", event[type]);
    switch (typeof event[type]) {
      case "object":
        // console.log("object");
        for (const key in event[type]) {
          attributes[type][key] = await generateDataLayerValue(
            event[type][key],
            options
          );
        }
        break;
      case "array":
        for (const key in event[type]) {
          attributes[type][key] = await generateDataLayerValue(
            event[type][key],
            options
          );
        }
        break;
      case "string":
        // console.log("string", type);
        attributes[type] = await generateDataLayerValue(event[type], options);
        // console.log("attributes[type]", attributes[type]);

        break;
      default:
        break;
    }
  }
  // console.log("attributes", attributes);
  return attributes;
};

const getEcommerceEvent = (eventtype) => {
  const events = [
    "view_item_list",
    "select_item",
    "view_item",
    "add_to_cart",
    "remove_from_cart",
    "begin_checkout",
    "add_shipping_info",
    "add_payment_info",
    "purchase",
    "view_promotion",
  ];
  return events.includes(eventtype);
};

const generateAnalytics = async (eventtype, options, module) => {
  const date = new Date().getTime();

  if (eventtype === "pageLoad") {
    window.dataLayer = window.dataLayer || [];
    const attributes = await generateDataLayer(eventtype, options);
    window.dataLayer.push({
      event: "orm_pageLoad",
      module,
      timestamp: date,
      type: eventtype,
      attributes,
      options,
    });
    return false;
  }
  if (getEcommerceEvent(eventtype)) {
    window.dataLayer = window.dataLayer || [];
    // window.dataLayer = { ecommerce: null };
    const ecommerce = await generateDataLayer(eventtype, options);
    window.dataLayer.push({
      event: eventtype,
      ecommerce,
    });
    return false;
  }

  const attributes = await generateDataLayer(eventtype, options);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventtype, // Formaly orm_click
    module,
    timestamp: date,
    type: eventtype,
    attributes,
  });

  // console.log("window.dataLayer", window.dataLayer);
};

const generateAdobe = async (eventtype, options, moduleName) => {
  // console.log("adobe", eventtype, options, moduleName);
  const config = JSON.parse(process.env.VUE_APP_ANALYTICS_CONFIG);
  // console.log(config);
  if (eventtype === "pageLoad") {
    eventtype = eventtype + "_" + options.pageType;
  }

  if (moduleName) {
    eventtype = eventtype + "_" + moduleName;
  }

  if (eventtype === "headerStep_jump" + "_" + moduleName) {
    eventtype = eventtype + "_" + options.to;
  }

  if (eventtype === "reservation_change" + "_" + moduleName) {
    eventtype = eventtype + "_" + options.to;
  }

  window.dataLayer = [];
  const event = config.orm[eventtype];
  // console.log(eventtype);
  // console.log(event);
  for (const key in event) {
    if (key !== "_satellite") {
      window.dataLayer[key] = await generateDataLayerValue(event[key], options);
    }
  }
  if (window._satellite) {
    window._satellite.track(event["_satellite"]);
  }

  // console.log(window.dataLayer);
};

const buildDataLayer = async (eventtype, options, moduleName) => {
  // console.log(process.env.VUE_APP_ANALYTICS_CONFIG);
  if (process.env.VUE_APP_ANALYTICS_DATALAYER === "true") {
    if (process.env.VUE_APP_ANALYTICS_ADOBE === "true") {
      await generateAdobe(eventtype, options, moduleName);
    } else {
      await generateAnalytics(eventtype, options, moduleName);
    }
  } else {
    return false;
  }
};

export { buildDataLayer };
