import { createApp } from "vue";
import App from "./App.vue";
import CustomerApp from "./CustomerApp.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
const customerwidget = createApp(CustomerApp);
// console.log("init");

async function initAPPS() {
  // console.log("getUsersession");
  await store.dispatch("userSession/SET_LOCALSTORAGE");
  // console.log("SET_LOCALSTORAGE");

  // const branch = store.getters["userSession/GET_BRANCH"];
  // console.log("branch", branch);
  app.use(router).mount("#crentReservation");

  app.directive("click-outside", {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function(event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
      document.body.addEventListener("touch", el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
      document.body.removeEventListener("touch", el.clickOutsideEvent);
    },
  });
  app.use(store);

  customerwidget
    .use(store)
    .use(router)
    .directive("click-outside", {
      beforeMount(el, binding) {
        el.clickOutsideEvent = function(event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, el);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
        document.body.addEventListener("touch", el.clickOutsideEvent);
      },
      unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("touch", el.clickOutsideEvent);
      },
    })
    .mount("#crentCustomerWidget");
}

// app.config.devtools = true;

// app.mixin({
//   created() {
//     const myOption = this.$options.myOption;
//     if (myOption) {
//       console.log(myOption);
//     }
//   },
// });

initAPPS();
