import { defineComponent } from "vue";
import store from "../store";

export default defineComponent({
  methods: {
    i18n(id, searchall) {
      if (searchall) {
        return store.getters["general/GET_TRANSLATION"](id, true);
      } else {
        return store.getters["general/GET_TRANSLATION"](id, false);
      }
    },
    stripHTML(value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      const text = div.textContent || div.innerText || "";
      return text;
    },
  },
});

export const i18nfunc = (id) => {
  return store.getters["general/GET_TRANSLATION"](id);
};
