import apiCall from "../../api/crentAPI";

const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";

const state = () => ({
  offers: [],
  offerSelected: {},
});

const getters = {
  GET_OFFERS: (state) => state.offers || null,
  GET_OFFERSELECTED: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "offer_selected")) ||
    state.offerSelected ||
    null,
};

const actions = {
  async SET_OFFERS({ commit }) {
    const payload = "";
    const result = await apiCall.getHeaders(
      "/api/rent/offers",
      payload,
      (result) => {
        if (result.status == 200) {
          commit("MUTATE_OFFERS", result.data);
          // console.log(result.data);
        }
      }
    );

    return result;
  },
  async SELECT_OFFER({ commit }, offer) {
    commit("MUTATE_OFFERSSELECTED", offer);
  },
  DELETE_OFFER({ commit }) {
    commit("MUTATE_DELETE_SELECTED");
  },
};

// mutations
const mutations = {
  MUTATE_OFFERS(state, data) {
    state.offers = JSON.parse(JSON.stringify(data));
    localStorage.setItem(ENV + "offers", JSON.stringify(data));
    return data;
  },
  MUTATE_OFFERSSELECTED(state, offer) {
    state.offerSelected = JSON.parse(JSON.stringify(offer));
    sessionStorage.setItem(ENV + "offer_selected", JSON.stringify(offer));
  },
  MUTATE_DELETE_SELECTED(state) {
    state.offerSelected = {};
    sessionStorage.removeItem(ENV + "offer_selected");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
