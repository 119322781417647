<template>
  <div class="orm-input orm-relative">
    <label class="orm-form__label" :for="myId"
      >{{ myLabel }}<span v-if="isRequired">*</span></label
    >
    <input
      class="orm-input__control"
      :id="myId"
      type="password"
      :value="modelValue"
      :placeholder="myPlaceholder"
      :readonly="isReadonly"
      :required="isRequired"
      :class="{ 'orm-form__error': error }"
      v-if="!showPwd"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input
      class="orm-input__control"
      :id="myId"
      type="text"
      :value="modelValue"
      :placeholder="myPlaceholder"
      :readonly="isReadonly"
      :required="isRequired"
      :class="{ 'orm-form__error': error }"
      v-if="showPwd"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div
      class="orm-input__showPwd"
      :style="[modelValue ? { opacity: '1' } : { opacity: '0.5' }]"
      @click="modelValue ? (showPwd = !showPwd) : false"
    >
      <i class="fa fa-eye orm-input__showPwd_eye"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    myId: {
      type: String,
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    myPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    myType: {
      type: String,
      required: false,
      default: "text",
    },
  },
  data() {
    return {
      showPwd: false,
    };
  },
  emits: ["update:modelValue"],
};
</script>
